<template>
  <div>
    <v-card class="mx-auto elevation-0">
      <v-card-title>
        <v-toolbar
          flat
          v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.fundo"
        >
          <v-icon
            left
            v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.icone"
            >{{
              utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.icone.nome
            }}</v-icon
          >
          <v-toolbar-title
            v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.titulo"
            >{{ lang("usuarios") }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset horizontal></v-divider>
          <div class="flex-grow-2"></div>
          <v-btn
            v-on:click="abrirJanelaFiltro()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.pesquisar.atalho"
            v-on:shortkey="
              !desabilitado && permissaoListar() ? abrirJanelaFiltro() : null
            "
            v-bind="utilImp.cnt.CONFIG.btn.pesquisar"
            v-bind:disabled="desabilitado || !permissaoListar()"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.pesquisar.icone }}</v-icon
            >{{ lang("pesquisar") }}</v-btn
          >
          <v-btn
            v-on:click="modoIncluir()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.incluir.atalho"
            v-on:shortkey="
              !desabilitado && permissaoIncluir() ? modoIncluir() : null
            "
            v-bind="utilImp.cnt.CONFIG.btn.incluir"
            v-bind:disabled="desabilitado || !permissaoIncluir()"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.incluir.icone }}</v-icon
            >{{ lang("incluir") }}</v-btn
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <!-- Listar -->
        <v-data-table
          class="elevation-0"
          height="70vh"
          fixed-header
          hide-default-footer
          v-bind:headers="titulo"
          v-bind:items="arrayUsuario.result"
          v-bind:items-per-page="itemPagina"
        >
          <!-- Campo -->
          <template v-slot:item.nomeCompleto="{ item }">
            <v-chip v-bind="utilImp.cnt.CONFIG.chip.cor.cinza">{{
              item.nomeCompleto
            }}</v-chip>
          </template>
          <template v-slot:item.ativo="{ item }">
            <v-icon class="mr-2">{{ iconeAtivo(item.ativo) }}</v-icon>
          </template>
          <template v-slot:item.dataRegistro="{ item }">{{
            formatarDataHora(item.dataServidor)
          }}</template>
          <!-- Acao Extra -->
          <template v-slot:item.acoes="{ item }">
            <v-icon
              class="mr-2"
              v-bind:title="lang('alterar')"
              v-on:click="modoAlterar(item)"
              v-bind:disabled="desabilitado || !permissaoAlterar()"
              >{{
                utilImp.cnt.CONFIG.icon.alterar +
                  " " +
                  utilImp.cnt.CONFIG.icon.tamanho.grande
              }}</v-icon
            >
            <v-icon
              class="mr-2"
              v-bind:title="lang('excluir')"
              v-on:click="modoExcluir(item)"
              v-bind:disabled="desabilitado || !permissaoExcluir()"
              >{{
                utilImp.cnt.CONFIG.icon.excluir +
                  " " +
                  utilImp.cnt.CONFIG.icon.tamanho.grande
              }}</v-icon
            >
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on }">
                <v-icon
                  light
                  class="mr-2"
                  v-on="on"
                  v-bind:style="{ cursor: 'pointer' }"
                  v-bind:title="lang('maisAcoes') + '...'"
                  v-bind:disabled="desabilitado || !permissaoAcao()"
                  >{{
                    utilImp.cnt.CONFIG.icon.acaoExtra +
                      " " +
                      utilImp.cnt.CONFIG.icon.tamanho.grande
                  }}</v-icon
                >
              </template>
              <v-list>
                <v-list-item
                  v-for="(itemAcaoExtra, index) in menuAcaoExtra"
                  v-bind:key="index"
                  v-on:click="acaoExtra(item.idUsuario, itemAcaoExtra.value)"
                  v-bind:disabled="desabilitado || itemAcaoExtra.disabled"
                >
                  <v-list-item-title>{{
                    itemAcaoExtra.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:no-data>
            <v-alert v-bind:value="false" color="white" icon="info">{{
              lang("nenhumRegistroEncontrado")
            }}</v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-pagination
          v-model="page"
          v-bind="paginacao"
          v-bind:disabled="desabilitado || !permissaoListar()"
          v-on:input="pesquisar()"
        ></v-pagination>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <!-- Pesquisar -->
    <v-dialog
      scrollable
      persistent
      v-model="dlgFiltrar"
      v-on:keydown.esc="validarFecharJanelaFiltro()"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.filtro
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline">{{ lang("pesquisar") }}</span>
          <v-divider class="mx-4" inset horizontal></v-divider>
          <v-spacer />
          <v-btn icon v-on:click="validarFecharJanelaFiltro()">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  name="namFiltroUsuario"
                  ref="refFiltroUsuario"
                  maxlength="30"
                  v-model="model.filtroUsuario"
                  v-bind:label="lang('nomeCompleto')"
                  v-bind="utilImp.cnt.CONFIG.textfield"
                  v-on:keyup.enter="pesquisar()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  v-model="model.usuarioAtivo"
                  v-bind:label="lang('ativo')"
                  v-bind="utilImp.cnt.CONFIG.checkbox"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" sm="10" md="10" />
              <v-col cols="2" sm="2" md="2">
                <v-select
                  style="max-width:100%"
                  v-model="qtdItemPagina"
                  v-bind:items="arrayItemPagina"
                  v-bind:label="lang('itensPorPagina')"
                  v-bind="utilImp.cnt.CONFIG.select"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <v-btn
            v-on:click="limparFiltro()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.limpar.atalho"
            v-on:shortkey="limparFiltro()"
            v-bind="utilImp.cnt.CONFIG.btn.limpar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.limpar.icone }}</v-icon
            >{{ lang("limpar") }}</v-btn
          >
          <div class="flex-grow-1"></div>
          <v-btn
            v-on:click="validarFecharJanelaFiltro()"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
          <v-btn
            v-on:click="confirmar()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.confirmar.atalho"
            v-on:shortkey="dlgFiltrar === true ? confirmar() : null"
            v-bind="utilImp.cnt.CONFIG.btn.confirmar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.confirmar.icone }}</v-icon
            >{{ lang("confirmar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Salvar -->
    <v-dialog
      scrollable
      persistent
      v-model="dlgSalvar"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.salvar
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline"
            >{{ cmpTituloAcao }} {{ lang("usuario") }}</span
          >
          <v-divider class="mx-4" inset horizontal></v-divider>
          <v-btn icon v-on:click="dlgSalvar = false">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- Inicio Guias -->
          <v-container>
            <v-tabs v-bind="utilImp.cnt.CONFIG.guia" v-model="activeTab">
              <v-tab v-bind:key="0">
                <v-icon left>mdi-account</v-icon>
                {{ lang("usuario") }}
              </v-tab>
              <v-tab v-bind:key="1">
                <v-icon left>mdi-bank</v-icon>
                {{ lang("unidadesAcesso") }}
              </v-tab>
              <v-tab v-bind:key="2">
                <v-icon left>mdi-lock</v-icon>
                {{ lang("perfil") }}
              </v-tab>
              <v-tab v-bind:key="3">
                <v-icon left>mdi-lock</v-icon>
                {{ lang("permissaoAvulsa") }}
              </v-tab>
              <!-- Guia Usuario -->
              <v-tab-item v-bind:key="0">
                <v-card flat>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="8" sm="8" md="8">
                          <v-text-field
                            name="namNomeCompleto"
                            ref="refNomeCompleto"
                            maxlength="100"
                            v-model="itemEditado.nomeCompleto"
                            v-bind:label="
                              lang('nomeCompleto') +
                                utilImp.cnt.CAMPO.obrigatorio
                            "
                            v-bind="utilImp.cnt.CONFIG.textfield"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4">
                          <v-checkbox
                            name="namAtivo"
                            v-model="itemEditado.ativo"
                            v-bind:label="lang('ativo')"
                            true-value="1"
                            false-value="0"
                            v-bind="utilImp.cnt.CONFIG.checkbox"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            name="namEmail"
                            ref="refEmail"
                            maxlength="100"
                            v-model="itemEditado.email"
                            v-bind:label="
                              lang('email') + utilImp.cnt.CAMPO.obrigatorio
                            "
                            v-bind="utilImp.cnt.CONFIG.textfield"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <!--
                            Opcao utilizando variavel especifica para o controle de atualizacao
                          <SelecionarUnidade v-model="itemEditado.unidadeBEA.idUnidade" v-bind:label="lang('unidade') +utilImp.cnt.CAMPO.obrigatorio" v-bind:atualizar="atualizarSelecionarUnidade" v-on:atualizar="atualizarSelecionarUnidade = $event"/>
                          -->
                          <selecionar-unidade
                            name="namUnidade"
                            v-model="itemEditado.unidadeBEA.idUnidade"
                            v-bind:label="
                              lang('unidade') + utilImp.cnt.CAMPO.obrigatorio
                            "
                            v-bind:atualizar="dlgSalvar"
                            v-bind:outlined="utilImp.cnt.CONFIG.select.outlined"
                            v-bind:backgroundColor="
                              utilImp.cnt.CONFIG.select.backgroundColor
                            "
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            name="namTelefone"
                            ref="refTelefone"
                            maxlength="50"
                            v-model="itemEditado.telefone"
                            v-bind:label="lang('telefone')"
                            v-bind="utilImp.cnt.CONFIG.textfield"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            auto-grow
                            name="namAnotacao"
                            ref="refAnotacao"
                            maxlength="200"
                            rows="3"
                            v-model="itemEditado.anotacao"
                            v-bind:label="lang('anotacao')"
                            v-bind="utilImp.cnt.CONFIG.textarea"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- Guia Unidade -->
              <v-tab-item v-bind:key="1">
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      class="elevation-0"
                      ref="refListarPerfil"
                      height="600px"
                      fixed-header
                      hide-default-footer
                      v-bind:headers="tituloUnidade"
                      v-bind:items="itemEditado.listUnidadeBEA"
                      v-bind:items-per-page="itemPagina"
                    >
                      <template v-slot:item.selecao="{ item }">
                        <v-checkbox
                          v-model="item.selecionado"
                          primary
                          hide-details
                          true-value="1"
                          false-value="0"
                          v-bind="utilImp.cnt.CONFIG.checkbox"
                        ></v-checkbox>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- Guia Perfil -->
              <v-tab-item v-bind:key="2">
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      class="elevation-0"
                      ref="refListarPerfil"
                      height="600px"
                      fixed-header
                      hide-default-footer
                      v-bind:headers="tituloPerfil"
                      v-bind:items="itemEditado.listPerfilBEA"
                      v-bind:items-per-page="itemPagina"
                    >
                      <template v-slot:item.selecao="{ item }">
                        <v-checkbox
                          v-model="item.selecionado"
                          primary
                          hide-details
                          true-value="1"
                          false-value="0"
                          v-bind="utilImp.cnt.CONFIG.checkbox"
                        ></v-checkbox>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- Guia Permissao Avulsa -->
              <v-tab-item v-bind:key="3">
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      class="elevation-0"
                      ref="refListarPerfil"
                      height="600px"
                      fixed-header
                      hide-default-footer
                      v-bind:headers="tituloPermissaoAvulsa"
                      v-bind:items="itemEditado.listPermissaoAvulsaBEA"
                      v-bind:items-per-page="itemPagina"
                    >
                      <template v-slot:item.selecao="{ item }">
                        <v-checkbox
                          v-model="item.selecionado"
                          primary
                          hide-details
                          true-value="1"
                          false-value="0"
                          v-bind="utilImp.cnt.CONFIG.checkbox"
                        ></v-checkbox>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-container>
          <!-- Fim Guias -->
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <div class="flex-grow-1"></div>
          <v-checkbox
            v-model="model.enviarEmail"
            v-bind:label="lang('enviarEmail')"
            v-bind="utilImp.cnt.CONFIG.checkbox"
          ></v-checkbox>
          <div class="flex-grow-1"></div>
          <v-btn
            v-on:click="fecharJanelaSalvar(false)"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
          <v-btn
            v-on:click="salvar()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.salvar.atalho"
            v-on:shortkey="dlgSalvar === true ? salvar() : null"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.salvar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.salvar.icone }}</v-icon
            >{{ lang("salvar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim Salvar -->
    <!-- Componentes -->
    <alterar-senha ref="refAlterarSenha" />
  </div>
</template>

<script>
import util from "../../../util/Util.js";
import SelecionarUnidade from "@/views/proprietario/unidade/SelecionarUnidade.vue";
import AlterarSenha from "@/views/proprietario/usuario/AlterarSenha.vue";

const ACAO_INCLUIR = "acaoIncluir";
const ACAO_ALTERAR = "acaoAlterar";
const ACAO_LISTAR = "acaoListar";
const ACAO_FECHAR_JANELA_FILTRO = "acaoFecharJanelaFiltro";
const ACAO_EXTRA_ENVIAR_EMAIL = "acaoExtraEnviarEmail";
const ACAO_EXTRA_ALTERAR_SENHA = "acaoExtraAlterarSenha";
const ITEM_PAGINA = 5000; // v-data-table
const ARRAY_ITEM_PAGINA = util.cnt.ARRAY.itemPagina1.qtd;
const POSICAO_ITEM_PAGINA = util.cnt.ARRAY.itemPagina1.posicao.p1;

export default {
  components: {
    "selecionar-unidade": SelecionarUnidade,
    "alterar-senha": AlterarSenha,
  },
  mixins: [util.mixUtil, util.mixAlerta],

  data: () => ({
    utilImp: util,
    dlgFiltrar: false,
    dlgSalvar: false,
    titulo: [],
    tituloUnidade: [],
    tituloPerfil: [],
    tituloPermissaoAvulsa: [],
    arrayUsuario: [],
    menuAcaoExtra: [],
    desabilitado: false,
    page: 1,
    itemPagina: ITEM_PAGINA,
    arrayItemPagina: ARRAY_ITEM_PAGINA,
    qtdItemPagina: ARRAY_ITEM_PAGINA[POSICAO_ITEM_PAGINA - 1],
    paginacao: { length: 1, totalVisible: 5, disabled: false },
    model: { filtroUsuario: "", usuarioAtivo: true, enviarEmail: false },
    validacao: {
      filtroUsuario: "max:30",
      nomeCompleto: "required|max:100",
      ativo: "required",
      senha: "required|min:6|max:30",
      unidade: "required",
      email: "required|email",
      telefone: "max:50",
      anotacao: "max:200",
    },
    indice: -1,
    itemEditado: {
      idUsuario: null,
      ativo: "",
      usuario: "",
      nomeCompleto: "",
      senha: "",
      email: "",
      telefone: "",
      anotacao: "",
      unidadeBEA: { idUnidade: null, unidade: "" },
      listPerfilBEA: [],
      listUnidadeBEA: [],
      listPermissaoAvulsaBEA: [],
    },
    itemPadrao: {
      idUsuario: null,
      ativo: "1",
      usuario: "",
      nomeCompleto: "",
      senha: "",
      email: "",
      telefone: "",
      anotacao: "",
      unidadeBEA: { idUnidade: null, unidade: "" },
      listPerfilBEA: [],
      listUnidadeBEA: [],
      listPermissaoAvulsaBEA: [],
    },
    activeTab: 0,
    exibirTextFieldSenha: false,
  }),

  computed: {
    cmpTituloAcao() {
      return this.indice === -1 ? this.lang("incluir") : this.lang("alterar");
    },
    cmpForcaSenha() {
      return this.verificarForcaSenha(this.itemEditado.senha);
    },
  },

  watch: {},

  created() {
    this.iniciar();
  },

  mounted() {
    // util.fncStore.unidade.incluirUnidade();
    this.listarUnidadeProprietario();
    this.listarPerfilProprietario();
    this.listarPermissaoAvulsa();
    this.pesquisar();
  },

  methods: {
    iniciar() {
      this.titulo = [
        // align: 'start' | 'center' | 'end'
        {
          text: this.lang("nomeCompleto"),
          align: "left",
          value: "nomeCompleto",
          sortable: true,
        },
        {
          text: this.lang("email"),
          align: "left",
          value: "email",
          sortable: true,
        },
        {
          text: this.lang("unidade"),
          align: "left",
          value: "unidadeBEA.unidade",
          sortable: false,
        },
        {
          text: this.lang("anotacao"),
          align: "left",
          value: "anotacao",
          sortable: false,
        },
        {
          text: this.lang("dataRegistro"),
          align: "left",
          value: "dataRegistro",
          sortable: true,
        },
        {
          text: this.lang("ativo"),
          align: "center",
          value: "ativo",
          sortable: true,
        },
        {
          text: this.lang("acoes"),
          align: "center",
          value: "acoes",
          sortable: false,
        },
      ];
      // Títulos unidade
      this.tituloUnidade = [
        {
          text: this.lang("selecao"),
          align: "left",
          value: "selecao",
          sortable: true,
        },
        {
          text: this.lang("unidade"),
          align: "left",
          value: "unidade",
          sortable: true,
        },
      ];
      // Títulos perfil
      this.tituloPerfil = [
        {
          text: this.lang("selecao"),
          align: "left",
          value: "selecao",
          sortable: true,
        },
        {
          text: this.lang("perfil"),
          align: "left",
          value: "perfil",
          sortable: true,
        },
      ];

      // Títulos permissão avulsa
      this.tituloPermissaoAvulsa = [
        {
          text: this.lang("selecao"),
          align: "left",
          value: "selecao",
          sortable: true,
        },
        {
          text: this.lang("id"),
          align: "left",
          value: "idPermissao",
          sortable: true,
        },
        {
          text: this.lang("chave"),
          align: "left",
          value: "chave",
          sortable: true,
        },
        {
          text: this.lang("descricao"),
          align: "left",
          value: "anotacao",
          sortable: true,
        },
      ];
      // Menu de opção extra
      this.menuAcaoExtra = [
        {
          title: this.lang("enviarEmail"),
          value: ACAO_EXTRA_ENVIAR_EMAIL,
          disabled: !this.permissaoEnviarEmail(),
        },
        {
          title: this.lang("alterarSenha"),
          value: ACAO_EXTRA_ALTERAR_SENHA,
          disabled: !this.permissaoAlterarSenha(),
        },
      ];
    },

    modoIncluir() {
      for (var i = 0; i < this.itemPadrao.listUnidadeBEA.length; i++) {
        this.itemPadrao.listUnidadeBEA[i].selecionado = util.cnt.BOLEANO.nao;
      }
      for (var j = 0; j < this.itemPadrao.listPerfilBEA.length; j++) {
        this.itemPadrao.listPerfilBEA[j].selecionado = util.cnt.BOLEANO.nao;
      }
      for (var k = 0; k < this.itemPadrao.listPermissaoAvulsaBEA.length; k++) {
        this.itemPadrao.listPermissaoAvulsaBEA[k].selecionado =
          util.cnt.BOLEANO.nao;
      }
      this.itemPadrao.unidadeBEA.idUnidade = null;
      this.indice = -1;
      this.itemEditado = Object.assign({}, this.itemPadrao);
      this.abrirJanelaSalvar();
    },

    modoAlterar(item) {
      this.indice = this.arrayUsuario.result.indexOf(item);
      this.itemEditado = Object.assign({}, item);
      this.abrirJanelaSalvar();
    },

    modoExcluir(item) {
      this.confirmDelete(this.lang("excluirUsuario"), item.usuario).then(
        (result) => {
          if (result.value) {
            this.excluir(item);
          }
        }
      );
    },

    salvar() {
      let acao = "";
      if (this.indice > -1) {
        // Alterar
        acao = ACAO_ALTERAR;
      } else {
        // Incluir
        acao = ACAO_INCLUIR;
      }
      this.validarSalvar(acao);
    },

    async validarSalvar(acao) {
      let result = "";
      result = await util.val.validate(
        this.itemEditado.nomeCompleto,
        this.validacao.nomeCompleto
      );
      if (!result.valid) {
        this.activeTab = 0;
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("nomeCompleto"))
        );
        return;
      }
      result = await util.val.validate(
        this.itemEditado.email,
        this.validacao.email
      );
      if (!result.valid) {
        this.activeTab = 0;
        this.alertWarningToast(util.val.message(result.errors, this.lang("email")));
        return;
      }
      if (this.exibirTextFieldSenha === true) {
        result = await util.val.validate(
          this.itemEditado.senha,
          this.validacao.senha
        );
        if (!result.valid) {
          this.activeTab = 0;
          this.alertWarningToast(
            util.val.message(result.errors, this.lang("senha"))
          );
          return;
        }
      }
      if (this.exibirTextFieldSenha === true) {
        if (!(await this.validarForcaSenha(this.itemEditado.senha))) {
          this.activeTab = 0;
          this.alertWarningToast(
            this.lang("senhaInvalida") + ". " + this.lang("digiteSenhaValida")
          );
          return;
        }
      }
      result = await util.val.validate(
        this.itemEditado.unidadeBEA.idUnidade,
        this.validacao.unidade
      );
      if (!result.valid) {
        this.activeTab = 0;
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("unidade"))
        );
        return;
      }
      result = await util.val.validate(
        this.itemEditado.telefone,
        this.validacao.telefone
      );
      if (!result.valid) {
        this.activeTab = 0;
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("telefone"))
        );
        return;
      }
      result = await util.val.validate(
        this.itemEditado.anotacao,
        this.validacao.anotacao
      );
      if (!result.valid) {
        this.activeTab = 0;
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("anotacao"))
        );
        return;
      }
      // Validar unidades de acessos selecionadas
      let flagUnidadeSelecionado = false;
      for (var i = 0; i < this.itemEditado.listUnidadeBEA.length; i++) {
        if (this.itemEditado.listUnidadeBEA[i].selecionado === "1") {
          flagUnidadeSelecionado = true;
        }
      }
      if (flagUnidadeSelecionado === false) {
        this.activeTab = 1;
        this.alertWarningToast(this.lang("selecioneUnidadesAcesso"));
        return;
      }

      // Validar perfil
      let flagPerfilSelecionado = false;
      for (var j = 0; j < this.itemEditado.listPerfilBEA.length; j++) {
        if (this.itemEditado.listPerfilBEA[j].selecionado === "1") {
          flagPerfilSelecionado = true;
        }
      }
      // Validar permissão avulsa
      let flagPermissaoAvulsaSelecionado = false;
      for (var k = 0; k < this.itemEditado.listPermissaoAvulsaBEA.length; k++) {
        if (this.itemEditado.listPermissaoAvulsaBEA[k].selecionado === "1") {
          flagPermissaoAvulsaSelecionado = true;
        }
      }
      if (
        flagPerfilSelecionado === false &&
        flagPermissaoAvulsaSelecionado === false
      ) {
        this.activeTab = 2;
        this.alertWarningToast(this.lang("selecioneUmPerfilOuPermissaoAvulsa"));
        return;
      }

      // Direcionar para inclusão
      if (acao === ACAO_INCLUIR) {
        this.incluir();
      }
      // Direcionar para alteração
      if (acao === ACAO_ALTERAR) {
        this.alterar();
      }
    },

    incluir() {
      this.desabilitar(true);
      this.itemEditado.usuario = this.itemEditado.email;
      util.srv.usuario
        .incluirUsuario(this.model.enviarEmail, this.itemEditado)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.limparItemEditado();
            this.fecharJanelaSalvar(true);
            this.pesquisar();
            this.alertSucessToast(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    alterar() {
      this.desabilitar(true);
      util.srv.usuario
        .alterarUsuario(this.model.enviarEmail, this.itemEditado)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.limparItemEditado();
            this.fecharJanelaSalvar(true);
            this.pesquisar();
            this.alertSucessToast(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    excluir(item) {
      this.desabilitar(true);
      util.srv.usuario
        .excluirUsuario(item.idUsuario)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.pesquisar();
            this.alertSucess(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    confirmar() {
      this.limparPaginador();
      this.pesquisar();
    },

    pesquisar() {
      if (this.dlgFiltrar) {
        // Caso a janela filtrar esteja aberta, ocorrera a validacao.
        this.validarPesquisar(ACAO_LISTAR);
      } else {
        this.listar();
      }
    },

    async validarPesquisar(acao) {
      let result = "";
      result = await util.val.validate(
        this.model.filtroUsuario,
        this.validacao.filtroUsuario
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("usuario"))
        );
        return;
      }
      // Direcionar para listar
      if (acao === ACAO_LISTAR) {
        this.listar();
      }
      // Direcionar para fechar a janela filtro
      if (acao === ACAO_FECHAR_JANELA_FILTRO) {
        this.fecharJanelaFiltro();
      }
    },

    listar() {
      this.desabilitar(true);
      this.limparLista();
      this.showLoader();
      let oculto = false;
      util.srv.usuario
        .listarUsuario(
          this.model.filtroUsuario,
          this.model.usuarioAtivo,
          oculto,
          this.pagina(),
          this.limite()
        )
        .then((response) => {
          this.distribuir(response.data);
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
          this.fecharJanelaFiltro();
          this.hideLoader();
        });
    },

    distribuir(arrayRegistro) {
      if (arrayRegistro.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
        if (arrayRegistro.result !== null) {
          this.arrayUsuario = arrayRegistro;
          this.paginador();
        } else {
          // this.alertInfo(this.lang("nenhumRegistroEncontrado"));
        }
      } else {
        this.alertWarning(this.langResultCode(arrayRegistro.resultCode));
      }
    },

    listarPerfilProprietario() {
      this.desabilitar(true);
      this.limparLista();
      this.carregando = true;
      let oculto = false;
      util.srv.perfil
        .listarPerfis(oculto)
        .then((response) => {
          this.distribuirPerfilProprietario(response.data);
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
          this.carregando = false;
        });
    },
    distribuirPerfilProprietario(arrayRegistro) {
      this.itemPadrao.listPerfilBEA = arrayRegistro.result;
    },
    listarUnidadeProprietario() {
      this.desabilitar(true);
      util.srv.unidade
        .listarUnidades()
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.distribuirUnidadeProprietario(response.data);
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },
    distribuirUnidadeProprietario(arrayRegistro) {
      this.itemPadrao.listUnidadeBEA = arrayRegistro.result;
    },

    listarPermissaoAvulsa() {
      this.desabilitar(true);
      util.srv.permissao
        .listarPermissao()
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.distribuirPermissaoAvulsa(response.data);
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },
    distribuirPermissaoAvulsa(arrayRegistro) {
      this.itemPadrao.listPermissaoAvulsaBEA = arrayRegistro.result;
    },

    acaoExtra(idUsuario, opcao) {
      if (opcao === ACAO_EXTRA_ENVIAR_EMAIL) {
        this.confirmarEnvioEmailAcesso(idUsuario);
      }
      if (opcao === ACAO_EXTRA_ALTERAR_SENHA) {
        this.exibirAlterarSenha(idUsuario);
      }
    },

    confirmarEnvioEmailAcesso(idUsuario) {
      this.confirm(
        this.lang("enviarEmail"),
        this.lang("desejaEnviarEmailAcesso")
      ).then(result => {
        if (result.value) {
          this.enviarEmailAcesso(idUsuario);
        }
      });
    },

    enviarEmailAcesso(idUsuario) {
      this.desabilitar(true);
      this.showLoader();
      util.srv.usuario
        .enviarEmail(idUsuario)
        .then(response => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.alertSucess(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
          this.hideLoader();
        });
    },

    exibirAlterarSenha(idUsuario) {
      this.$refs.refAlterarSenha.exibir(idUsuario);
    },

    obterForcaSenha: function(senha) {
      return util.fnc.strengthPassword(senha);
    },
    validarForcaSenha: function(senha) {
      let forca = this.obterForcaSenha(senha);
      if (forca >= 3) {
        return true;
      } else {
        return false;
      }
    },
    verificarForcaSenha(senha) {
      let forca = this.obterForcaSenha(senha);
      // this.textoForcaSenha = forca
      if (forca === 0) {
        this.propForcaSenha.confirmed = false;
        this.propForcaSenha.text = "...";
        this.propForcaSenha.color = "grey";
      } else if (forca === 1) {
        // vermelho - Digitar mais caracteres
        this.propForcaSenha.confirmed = false;
        this.propForcaSenha.text = this.lang("senhaFraca");
        this.propForcaSenha.color = "red";
      } else if (forca === 2) {
        // vermelho - fraca
        this.propForcaSenha.confirmed = false;
        this.propForcaSenha.text = this.lang("senhaFraca");
        this.propForcaSenha.color = "red";
      } else if (forca === 3) {
        // verde - média
        this.propForcaSenha.confirmed = true;
        this.propForcaSenha.text = this.lang("senhaMedia");
        this.propForcaSenha.color = "green";
      } else if (forca === 4) {
        // azul - forte
        this.propForcaSenha.confirmed = true;
        this.propForcaSenha.text = this.lang("senhaForte");
        this.propForcaSenha.color = "blue";
      }
    },

    abrirJanelaFiltro() {
      this.dlgFiltrar = true;
      this.setFocusFiltroUsuario();
    },
    abrirJanelaSalvar() {
      this.dlgSalvar = true;
      this.activeTab = 0;
      this.setFocusUsuario();
      // this.atualizarSelecionarUnidade = true;
    },

    fecharJanelaFiltro() {
      if (!this.desabilitado) {
        this.dlgFiltrar = false;
      }
    },
    fecharJanelaSalvar(passeLivre) {
      if (!passeLivre) {
        if (!this.desabilitado) {
          this.dlgSalvar = false;
        }
      } else {
        this.dlgSalvar = false;
      }
    },
    /* Metodo responsavel por fechar a janela de pesquisa somente se os filtros forem validos */
    /* Necessario para que o paginador não execute a consulta com parametros invalidos */
    validarFecharJanelaFiltro() {
      this.validarPesquisar(ACAO_FECHAR_JANELA_FILTRO);
    },

    limparLista() {
      this.arrayUsuario = [];
    },
    limparFiltro() {
      this.model.filtroUsuario = "";
      this.setFocusFiltroUsuario();
    },
    limparItemEditado() {
      this.itemEditado = Object.assign({}, this.itemPadrao);
    },
    limparPaginador() {
      this.page = 1;
      this.paginacao.length = 1;
    },

    desabilitar(desabilitado) {
      this.desabilitado = desabilitado;
    },

    setFocusFiltroUsuario() {
      setTimeout(() => {
        this.$refs.refFiltroUsuario.focus();
      }, util.cnt.CONFIG.timer.focus);
    },

    setFocusUsuario() {
      setTimeout(() => {
        this.$refs.refNomeCompleto.focus();
      }, util.cnt.CONFIG.timer.focus);
    },

    iconeAtivo: function(valor) {
      return valor === util.cnt.BOLEANO.sim
        ? util.cnt.CONFIG.icon.sim + " " + util.cnt.CONFIG.icon.tamanho.grande
        : util.cnt.CONFIG.icon.nao + " " + util.cnt.CONFIG.icon.tamanho.grande;
    },

    paginador() {
      let contRegistro = this.arrayUsuario.result.length;
      if (contRegistro >= this.qtdItemPagina) {
        this.paginacao.length = this.page + 1;
      }
    },

    pagina: function() {
      return this.page - 1;
    },

    limite: function() {
      return this.qtdItemPagina;
    },

    permissaoListar() {
      return this.verificarPermissao(util.prm.P_1016);
    },
    permissaoIncluir() {
      return this.verificarPermissao(util.prm.P_1017);
    },
    permissaoAlterar() {
      return this.verificarPermissao(util.prm.P_1018);
    },
    permissaoExcluir() {
      return this.verificarPermissao(util.prm.P_1019);
    },
    permissaoAcao() {
      return this.verificarPermissao(util.prm.P_1020);
    },
    permissaoAlterarSenha() {
      return this.verificarPermissao(util.prm.P_1021);
    },
    permissaoEnviarEmail() {
      return this.verificarPermissao(util.prm.P_1022);
    },
  },
};
</script>
